<template>
  <footer class="aw-box">
    <div class="aw-box-inner mw-100">
      <div class="d-flex flex-wrap justify-content-between w-100">
        <ul id="menu-social-media" class="aw-menu socialmedia"><li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-22014"><a href="https://chaos.social/@algorithmwatch"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-2 -2 30 30" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"><path d="M25.426 15.547c-.383 1.844-3.422 3.844-6.914 4.226-1.813.2-3.602.391-5.524.313a34.967 34.967 0 0 1-5.582-.695c0 .28.012.558.047.804.41 2.875 3.063 3.043 5.57 3.133 2.547.067 4.805-.594 4.805-.594l.094 2.125s-1.766.895-4.922 1.063c-1.75.09-3.914-.043-6.434-.672C1.106 23.922.168 18.496.023 12.996-.023 11.352.012 9.81.012 8.5c0-5.625 3.96-7.266 3.96-7.266C5.954.394 9.376.027 12.943.004h.081c3.567.023 6.989.39 8.98 1.23 0 0 3.962 1.653 3.962 7.29 0 0 .047 4.148-.55 7.035"></path><path d="M21.309 8.95v6.82h-2.895V9.172c0-1.399-.637-2.102-1.894-2.102-1.395 0-2.09.84-2.09 2.492v3.626h-2.883V9.55c0-1.653-.695-2.492-2.086-2.492-1.262-.012-1.899.703-1.899 2.101v6.61H4.684V8.95c0-1.387.382-2.497 1.136-3.31.793-.816 1.828-1.23 3.11-1.23 1.488 0 2.605.524 3.351 1.586L13 7.113l.723-1.117c.726-1.059 2.011-1.672 3.359-1.598 1.273 0 2.305.426 3.098 1.243a4.667 4.667 0 0 1 1.14 3.32"></path></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11445"><a title="Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/algorithmwatch"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M23 3a10.9 10.9 0 01-3.1 1.5 4.5 4.5 0 00-7.9 3v1A10.7 10.7 0 013 4s-4 9 5 13a11.6 11.6 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 000-.8A7.7 7.7 0 0023 3z"></path></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11446"><a title="LinkedIn" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/algorithmwatch/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path><circle cx="4" cy="4" r="2"></circle></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11447"><a title="Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/algorithmwatch/posts/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" style="margin-left:-5px;margin-right:-3px" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11448"><a title="Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/algorithmwatch/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.4A4 4 0 1112.6 8a4 4 0 013.4 3.4zM17.5 6.5h0"></path></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11449"><a title="Youtube" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCOhGvpEH0oLlpqZDDpDo67Q"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M22.5 6.4a2.8 2.8 0 00-1.9-2C18.9 4 12 4 12 4s-6.9 0-8.6.5a2.8 2.8 0 00-2 2 29 29 0 00-.4 5.3 29 29 0 00.5 5.3A2.8 2.8 0 003.4 19c1.7.5 8.6.5 8.6.5s6.9 0 8.6-.5a2.8 2.8 0 002-2 29 29 0 00.4-5.3 29 29 0 00-.5-5.3z"></path><path fill="currentColor" d="M9.8 15l5.7-3.3-5.8-3.2V15z"></path></svg></a></li>
          <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-11539"><a title="RSS Feed" href="https://algorithmwatch.org/en/rss"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 11a9 9 0 019 9M4 4a16 16 0 0116 16"></path><circle cx="5" cy="19" r="1"></circle></svg></a></li>
        </ul><ul id="menu-footer" class="aw-menu"><li id="menu-item-3677" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3677"><span class="slash-before"></span><a href="https://algorithmwatch.org/en/contact/">Contact</a><span class="slash-after"></span></li>
          <li id="menu-item-3676" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3676"><span class="slash-before"></span><a href="https://algorithmwatch.org/en/legal-notice/">Legal Notice</a><span class="slash-after"></span></li>
          <li id="menu-item-2061" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2061"><span class="slash-before"></span><a href="https://algorithmwatch.org/en/privacy/">Privacy</a><span class="slash-after"></span></li>
          <li id="menu-item-16613" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-16613"><span class="slash-before"></span><a href="https://algorithmwatch.org/en/transparency/">Transparency</a><span class="slash-after"></span></li>
        </ul></div>
      </div>
      <div class="aw-box-inner pb-2">
               <br>
        <small class="d-block mt-1">©&nbsp;AlgorithmWatch&nbsp;2023</small>
      </div>
  </footer>
</template>

<style>
  .aw-box-inner {
    padding: 20px 0;
  }

</style>