const texts = {
  en: {
    "Automated Society" : "Automated Society_",
    "About": "About",
    "Browse the archive": "Browse the archive",
    "Get the briefing..." : "Get the briefing on how <span class='red_em'>automated&nbsp;systems</span> impact <span class='yellow_em'>real&nbsp;people</span>, <span class='blue_em'>in&nbsp;Europe</span> and beyond.",
    "Join over..." : "Join over 7,000 readers or ",
    "Read the latest issue" : "read the latest issue",
    "I agree...": "I agree to the <a href='https://algorithmwatch.org/en/privacy/'>privacy policy</a> and know that I can unsubscribe at any time ",
    "Subscribe for free": "Subscribe for free",
    "More from AlgorithmWatch": "More from AlgorithmWatch",
    "The Briefing": "The Briefing",
    "Briefings": "Briefings",
    "Algorithm news from Europe":"Algorithm news from Europe",
    "We read it, so you don't have to": "We read it, so you don't have to",
    "Other algorithm news": "Other algorithm news",
    "Opinions": "Opinion",
    "View online version": "View online",
    "Unsubscribe": "Unsubscribe",
    "News items": "News items",
    "Opinions and other links": "Opinions and other links",
    "share_emailsubject": "Check out the Automated Society newsletter",
    "share_emailbody" : "Automated Society is a 5-minute read that provides the context you need to make sense of automated systems – and their regulation.\n\nSubscribe at https://automatedsociety.algorithmwatch.org or read the latest issue here ",
    "Subscribe": "Subscribe",
    "This email was forwarded to you? You can" : "This email was forwarded to you? You can",
    "subscribe here" : "subscribe here",
    "author": "reporter",
    "author_f": "reporter",
    "Please help us reach out to people who could be interested in automated decision-making in Europe." : "Please help us reach people interested in automated decision-making in Europe.",
    "Recommend this newsletter over": "Recommend this newsletter over",
    "email" : "email",
    "or" : "or",
    "Archive": "Archive",
    "Browse through over 1,000 items that were featured in the newsletter since 2019. You can filter the list by keyword using the search bar.": "Browse through over 1,000 items that were featured in the newsletter since 2019. You can filter the list by keyword using the search bar.",
    "Filter by keyword": "Filter by keyword",
    "Selected filters": "Selected filters",
    "AUTOMATED SOCIETY_ is probably the best newsletter about Artificial Intelligence, by Europe's foremost organization on the topic.": "AUTOMATED SOCIETY_ is probably the best newsletter about Artificial Intelligence, by Europe's foremost organization on the topic.",
    "Short.": "Short.",
    "Accessible.": "Accessible.",
    "Sharp.": "Sharp.",
    "European.": "European.",
    "Automated Society is a 5-minute read that provides the context you need to make sense of automated systems – and their regulation.": "Automated Society is a 5-minute read that provides the context you need to make sense of automated systems – and their regulation.",
    "Automated Society provides over 7,000 decision-makers, academics and journalists with a selection of news stories and academic articles that explain how automated systems impact all of us.": "Automated Society provides over 7,000 decision-makers, academics and journalists with a selection of news stories and academic articles that explain how automated systems impact all of us.",
    "It cuts through the jargon and the exaggerations that clutter public discourse around new technologies and keeps only relevant pieces of information.": "It cuts through the jargon and the exaggerations that clutter public discourse around new technologies and keeps only relevant pieces of information.",
    "It does not focus on stories from Silicon Valley that you have read elsewhere. Rather, it goes in depth, on our continent, to reveal how different groups of people use, or are subjected to, Artificial Intelligence, Machine Learning or simpler algorithms that can turn their lives upside down.": "It does not focus on stories from Silicon Valley. Rather, it goes in depth, on our continent, to reveal how different groups of people use, or are subjected to, algorithms that can turn their lives upside down.",
    "The Automated Society newsletter is a great resource for interesting news from around Europe - I’m an avid reader.": "The Automated Society newsletter is a great resource for interesting news from around Europe - I’m an avid reader.",
    "When I read Automated Society, I always find something I haven't heard about, with a strong European angle.": "When I read Automated Society, I always find something I haven't heard about, with a strong European angle.",
    "Independent journalist and author of the #Règle30 newsletter": "Independent journalist and author of the #Règle30 newsletter",
    "France": "France",
    "Radboud University, Nijmegen, The Netherlands": "Radboud University, Nijmegen, The Netherlands",
    "Professor ICT and Law at the iHub, Radboud's interdisciplinary research hub on digitalization and society": "Professor ICT and Law at the iHub, Radboud's interdisciplinary research hub on digitalization and society",
    "Donate": "Donate",
    "Your email": "Your email",
    "Every two weeks": "Every two weeks.",
    "as seen in Automated Society": "Just read in the Automated Society newsletter: ",
    "in" : "in",
    "thats it": 'That\'s it for now, thanks for reading! If you want to support our work, you can <a href="https://algorithmwatch.org/en/donate/">make a donation here</a>. As always, you can answer this e-mail with tips or comments; I\'d love to read from you.',
    "Germany": "Germany",
    "Youre now subscribed to Automated Society_":"You're now subscribed to Automated Society_",
    "See you when the next issue of the newsletter is sent":"Looking forward to reading your feedback on the next issue of the newsletter!",
    "Thanks for reading":"Thanks for reading,",
    "Thanks for subscribing! Please check your email inbox to confirm your subscription.": "Thanks for subscribing! Please check your email inbox to confirm your subscription.",
    "An error occured, sorry! It's usually that the email address you input is already subscribed.": "An error occured, sorry! It's usually that the email address you input is already subscribed."
  },
  de: {
    "Automated Society" : "Die automatisierte Gesellschaft_",
    "About": "Der Newsletter",
    "Browse the archive": "Das Archiv",
    "Get the briefing..." : "Wie sich <span class='red_em'>automatisierte&nbsp;Systeme</span> auf <span class='yellow_em'>Menschen</span> in <span class='blue_em'>Europa</span> auswirken.",
    "Join over..." : "Schließe dich über 7.000 Abonnent*innen an oder ",
    "Read the latest issue" : "lies die letzte Ausgabe",
    "I agree...": "Ich bin mit der <a href='https://algorithmwatch.org/de/datenschutz/'>Verarbeitung meiner Daten</a> einverstanden und weiß, dass ich den Newsletter jederzeit abbestellen kann. ",
    "Subscribe for free": "Kostenlos abonnieren",
    "More from AlgorithmWatch": "Mehr von AlgorithmWatch",
    "The Briefing": "Das Briefing",
    "Briefings": "Briefings",
    "Algorithm news from Europe":"Algorithmen-Nachrichten aus Europa",
    "We read it, so you don't have to": "Zusammengefasst",
    "Other algorithm news": "Andere Algorithmen-Nachrichten",
    "Opinions": "Meinung",
    "View online version": "Im Browser lesen",
    "Unsubscribe": "Abbestellen",
    "News items": "Nachrichten",
    "Opinions and other links": "Meinungen und mehr",
    "share_emailsubject": "Kennst du diesen Newsletter?",
    "share_emailbody" : "Die automatisierte Gesellschaft_ ist eine 5-minütige Lektüre, die den Kontext liefert, den Sie benötigen, um automatisierte Systeme – und ihre Regulierung – zu verstehen.\n\nHier kannst du abonnieren https://automatedsociety.algorithmwatch.org und hier kannst du die letzte Ausgabe lesen ",
    "Subscribe": "Abonnieren",
    "This email was forwarded to you? You can" : "Diese E-Mail wurde an dich weitergeleitet? Du kannst",
    "subscribe here" : "hier abonnieren",
    "author": "Autor",
    "author_f": "Autorin",
    "Please help us reach out to people who could be interested in automated decision-making in Europe." : "Bitte hilf uns, Menschen zu erreichen, die an automatisierter Entscheidungsfindung in Europa interessiert sein könnten.",
    "Recommend this newsletter over": "Empfiehl unseren Newsletter per",
    "email" : "Email",
    "or" : "oder auf",
    "Archive": "Das Archiv",
    "Browse through over 1,000 items that were featured in the newsletter since 2019. You can filter the list by keyword using the search bar.": "Stöbere in über 1.000 Artikeln, die seit 2019 im Newsletter vorgestellt wurden. Über die Suchleiste kannst du die Liste nach (englischen) Stichworten filtern.",
    "Filter by keyword": "Nach Stichworten filtern",
    "Selected filters": "Aktive Filter",
    "AUTOMATED SOCIETY_ is probably the best newsletter about Artificial Intelligence, by Europe's foremost organization on the topic.": "DIE AUTOMATISIERTE GESELLSCHAFT_ ist vielleicht der beste Newsletter über Künstliche Intelligenz, von Europas führender Organisation auf dem Gebiet.",
    "Short.": "Prägnant.",
    "Accessible.": "Informativ.",
    "Sharp.": "Aufs Wesentliche konzentriert.",
    "European.": "Die europäische Perspektive.",
    "Automated Society is a 5-minute read that provides the context you need to make sense of automated systems – and their regulation.": "DIE AUTOMATISIERTE GESELLSCHAFT_ liefert in einer 5-minütigen Lektüre den Kontext, um automatisierte Systeme – und ihre Regulierung – zu verstehen.",
    "Automated Society provides over 7,000 decision-makers, academics and journalists with a selection of news stories and academic articles that explain how automated systems impact all of us.": "Über 7.000 Entscheidungsträger*innen, Akademiker*innen und Journalist*innen lesen DIE AUTOMATISIERTE GESELLSCHAFT_, um einen Überblick über automatisierte Systeme und ihre Auswirkungen auf uns alle zu erhalten. Wir berichten über aktuelle Nachrichten und fassen wissenschaftliche Artikel zum Thema zusammen.",
    "It cuts through the jargon and the exaggerations that clutter public discourse around new technologies and keeps only relevant pieces of information.": "DIE AUTOMATISIERTE GESELLSCHAFT_ verzichtet auf den Jargon und die Übertreibungen, unter denen der öffentliche Diskurs über neue Technologien leidet. Unser Newsletter liefert stattdessen die wichtigsten Informationen.",
    "It does not focus on stories from Silicon Valley that you have read elsewhere. Rather, it goes in depth, on our continent, to reveal how different groups of people use, or are subjected to, Artificial Intelligence, Machine Learning or simpler algorithms that can turn their lives upside down.": "DIE AUTOMATISIERTE GESELLSCHAFT_ wärmt nicht Geschichten aus dem Silicon Valley auf. Wir zeigen, wie Technologien Künstlicher Intelligenz und Maschinellen Lernens das Leben der Menschen in Europa auf den Kopf stellen können.",
    "The Automated Society newsletter is a great resource for interesting news from around Europe - I’m an avid reader.": "Der Newsletter Die automatisierte Gesellschaft_ ist eine großartige Quelle für interessante Neuigkeiten aus ganz Europa – ich bin ein begeisterter Leser.",
    "When I read Automated Society, I always find something I haven't heard about, with a strong European angle.": "Wenn ich Die Automatisierte Gesellschaft_ lese, finde ich immer etwas, wovon ich noch nie gehört habe, auch dank des starken europäischen Schwerpunkts.",
    "Independent journalist and author of the #Règle30 newsletter": "Unabhängige Journalistin und Autorin des Newsletters #Règle30",
    "France": "Frankreich",
    "Radboud University, Nijmegen, The Netherlands": "Radboud-Universität Nijmegen, Niederlande",
    "Professor ICT and Law at the iHub, Radboud's interdisciplinary research hub on digitalization and society": "Professor für Informations- und Kommunikationstechnik (ITK) und Recht am iHub, dem interdisziplinärem Forschungszentrum zu Digitalisierung und Gesellschaft an der",
    "Donate": "Spenden",
    "Your email": "Deine Email",
    "Every two weeks": "zweiwöchentlich.",
    "as seen in Automated Society": "Gerade im Newsletter 'Die automatisierte Gesellschaft_' gelesen: ",
    "in" : "auf",
    "thats it": 'Das war\'s für heute, vielen Dank für das Interesse! Wir würden uns freuen, wenn Ihr unsere Arbeit mit einer <a href="https://algorithmwatch.org/de/spenden/">Spende</a> unterstützt. Wenn Ihr auf diese E-Mail antwortet, könnt Ihr mir Hinweise oder Kommentare schicken. Wie immer würde ich mich darüber freuen, von euch zu hören!',
    "Germany": "Deutschland",
    "Youre now subscribed to Automated Society_":"Du hast jetzt der Automatisierten Gesellschaft_ abonniert",
    "See you when the next issue of the newsletter is sent":"Ich freue mich auf dein Feedback zum nächsten Newsletter!",
    "Thanks for reading":"Vielen Dank fürs Lesen,",
    "Thanks for subscribing! Please check your email inbox to confirm your subscription.": "Vielen Dank! Bitte checke deine Emails um dein Abonnement zu bestätigen.",
    "An error occured, sorry! It's usually that the email address you input is already subscribed.": "Es gab ein Fehler, sorry! Kann es sein, dass deine Email Adresse schon abonniert wurde?"
  }
}

export default texts;
