<template>
  <headerAW 
    :currentLang="currentLang" />
  <main class="aw-box container is-max-desktop">
    <div class="aw-box hero is-medium">
          <div class="hero-body less-padding">
            <div class="columns">
              <div class="column is-two-thirds content">
                <p></p>
                <p class="subtitle">
                  {{ texts[currentLang]["Youre now subscribed to Automated Society_"] }}
                </p>
                <p >
                  {{ texts[currentLang]["See you when the next issue of the newsletter is sent"] }}
                </p>
                <p >
                  {{ texts[currentLang]["Thanks for reading"] }}
                </p>
                <p >
                  Nicolas Kayser-Bril – kayser-bril@algorithmwatch.org
                </p>
              </div>
              
              <div class="column is-hidden-mobile">
                <img src="SmartphoneLanding.png" alt="A smartphone with the newsletter" class="smartphone"/>
              </div>
            </div>
          </div>
        </div>
  </main>
  <footerAW />
</template>

<script>
import headerAW from './components/headerAW.vue';
import footerAW from './components/footerAW.vue';
import texts from './assets/texts.js'

export default {
  name: 'Thanks',
  components: {
    headerAW,
    footerAW
  },
  data() {
    return {
      currentLang: 'en',
      texts:texts
    }
  },
  methods : {
    setLang(value) {
      this.currentLang = value
    }
  },
  created() {
    this.$watch(
          () => this.$route.params,
          () => {
            this.setLang(this.$route.params.lang)
          },
          { immediate: true })
  }
}
</script>

<style scoped>

@import "~bulma/css/bulma.css";

.aw-box {
  max-width: 1200px;
}

.title {
  font-size: 36px;
  color:#6D6D6D; 
  font-weight: 300;
  text-transform: uppercase;
}
</style>
