<template>
    <div class="block subscribe-claim">
      {{ texts[currentLang]["Join over..."] }}
      <a target="_blank" :href="currentLang=='en' ? latestIssueURLEnglish : latestIssueURLGerman">
        {{ texts[currentLang]["Read the latest issue"] }}
      </a>.
    </div>

    <form class="mj-form" @submit.prevent="onSubmit">
        <div class="field is-grouped">
          <p class="control is-expanded">
            <input class="input" type="email" :placeholder='texts[currentLang]["Your email"]' name="email" v-model="email" required="required">
          </p>
          <p class="control submit-email">
            <button class="button subscribe-button" @click="submitData()">{{ texts[currentLang]["Subscribe for free"] }}</button>
          </p>
        </div>
        <div class="field is-size-6" :class="newsletterSuccess ? 'is-invisible' : 'is-visible'">
          <label class="checkbox">
            <input type="checkbox" v-model="consented" required="required">
              &nbsp;<span v-html='texts[currentLang]["I agree..."]'></span>
          </label>
        </div>
      </form>
      <div class="success-subscribe" :class="newsletterSuccess ? 'is-visible' : 'is-invisible' ">
        
        <article class="message is-info">
          <div class="message-body">
            {{ texts[currentLang]["Thanks for subscribing! Please check your email inbox to confirm your subscription."] }}
          </div>
        </article>

      </div>
      <div class="error-subscribe" :class="newsletterError ? 'is-visible' : 'is-invisible' ">
        <article class="message is-error">
          <div class="message-body">
            {{ texts[currentLang]["An error occured, sorry! It's usually that the email address you input is already subscribed."] }}
          </div>
        </article>
      </div>
</template>


<script>
import texts from '../assets/texts.js'

export default {
  name: 'subscribeForm',
  data() {
    return {
      texts: texts,
      email:'',
      consented:false,
      newsletterError: false,
      newsletterSuccess: false
    }
  },
  props: ['currentLang', 'latestIssueURLEnglish', 'latestIssueURLGerman'],
  methods : {
    postData(url = '', data = {}) {
        return fetch(url, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
      },
    submitData() {

          var data = {
            Email: this.email,
            Fields: [{ID: 72649, Value: 'organic'}]
          };

          const urlEnglish = "https://r.algorithmwatch.org/wgt/x3ysn/14/subscribe?c=51e1330e"
          const urlGerman = "https://r.algorithmwatch.org/wgt/x3ysn/4ij/subscribe?c=9a73cec4"

          // Checks if user comes from paid LinkedIn campaign
          if (window.location.href.includes("li_fat_id")) {
            data["Fields"][0]["Value"] = "LinkedIn"
          }

          var url = this.currentLang == 'en' ? urlEnglish : urlGerman
          
          // LinkedIn tracker
          window.lintrk('track', { conversion_id: 12331770 });

          if (this.consented) { 
            this.newsletterSuccess = false;
            this.newsletterError = false;
            this.postData(url, data)
              .then(() => {
                this.newsletterSuccess = true
              }).catch(() => {
                this.newsletterError = true
              });
          }
      }
  }
}
</script>

<style>

.subscribe-claim {
  margin-bottom: 0 !important;
}

.submit-email {
  margin:auto;
}

.subscribe-button {
  background: #4F7BA2;
  color:white;
  font-weight: bold; 
}

.subscribe-button:hover {
  color:white !important;
}

</style>


