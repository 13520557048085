<template>
  <header class="aw-box">
    <nav class="navbar is-transparent">
      <div class="navbar-brand is-hidden-mobile">
        <a class="navbar-item" href="https://algorithmwatch.org">
          <img src="https://static.algorithmwatch.org/gfx/aw-logo.svg" alt="AlgorithmWatch">
        </a>
      </div>
      <div class="navbar-brand">
        <span class="newsletter_title navbar-item">
          {{ texts[currentLang]["Automated Society"] }}
        </span>
        <div class="navbar-burger" data-target="navbarExampleTransparentExample" @click="menuActive == true ? menuActive = false : menuActive = true" :class="{'is-active': menuActive}">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="navbarExampleTransparentExample" class="navbar-menu" :class="{'is-active': menuActive}">
        <div class="navbar-start">
          <router-link class="navbar-item" :to="'/' + currentLang + '/' ">
            {{ texts[currentLang]["About"] }}
          </router-link>
          <router-link class="navbar-item" :to="'/' + currentLang + '/archive/'">
            {{ texts[currentLang]["Browse the archive"] }}
          </router-link>
          <a class="navbar-item" @click="updatePathParams({'lang': 'de'})" v-if="currentLang == 'en'">
            Switch to German
          </a>
          <a class="navbar-item" @click="updatePathParams({'lang': 'en'})" v-if="currentLang == 'de'">
            Switch to English
          </a>
          </div>
        </div>
    </nav>

    </header>

</template>
<script>
import texts from '../assets/texts.js';

export default {
  name: 'headerAW',
  data() {
    return { menuActive: false, texts: texts }
  },
  props: [ 'currentLang'],
  methods: {
    updatePathParams(newParams) {
      this.$router.push({ params: newParams });
    }

  }
}
</script>
<style scoped>
  .aw-box {
    padding: 0;
  }
  nav {
    padding: 20px;
  }
  #logo img {
    max-width: inherit;
  }
  .newsletter_title {
    text-transform: uppercase;
    font-family: Open Sans, sans; 
    font-size: 18px;
    color:#6D6D6D; 
    font-weight: 300;
  }
</style>
