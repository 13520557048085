const langs = {
	"de" : { "en": "German", "de": "Deutsch"},
	"en" : { "en": "English", "de": "Englisch"},
	"fr" : { "en": "French", "de": "Französisch"},
	"es" : { "en": "Spanish", "de": "Spanisch"},
	"pt" : { "en": "Portuguese", "de": "Portuguesisch"},
	"sv" : { "en": "Swedish", "de": "Schwedisch"},
	"it" : { "en": "Italian", "de": "Italienisch"},
	"ru" : { "en": "Russian", "de": "Russisch"},
	"nl" : { "en": "Dutch", "de": "Niederländisch"},
	"dk" : { "en": "Danish", "de": "Dänisch"},
	"hu" : { "en": "Hungarian", "de": "Ungarisch"},
	"zh" : { "en": "Chinese", "de": "Chinesisch"},
	"lt" : { "en": "Lithuanien", "de": "Litauisch"},
	"fi" : { "en": "Finnish", "de": "Finnisch"},
	"ro" : { "en": "Romanian", "de": "Rumänisch"},
	"pl" : { "en": "Polish", "de": "Polnisch"},
	"he" : { "en": "Hebrew", "de": "Hebräisch"},
	"cs" : { "en": "Czech", "de": "Tschechisch"},
	"et" : { "en": "Estonian", "de": "Estnisch"},
	"ja" : { "en": "Japanese", "de": "Japanisch"},
	"ca" : { "en": "Catalan", "de": "Katalanisch"},
	"el" : { "en": "Greek", "de": "Grieschich"},
	'fr, de, en, es, pl' : {"en" : "multiple languages", "de" : "mehrere Sprache"}
}

export default langs