<template>
  <headerAW 
    :currentLang="currentLang" />
  
  <section>
    <div class="aw-box hero is-medium">
      <div class="hero-body less-padding">
        <div class="columns">
          <div class="column is-two-thirds">
            <p class="title">
              {{ texts[currentLang]["Automated Society"] }}
            </p>
            <p class="subtitle no-margin-bottom" v-html='texts[currentLang]["Get the briefing..."]'></p>
            <p class="frequency">🗓️ <span v-html='texts[currentLang]["Every two weeks"]'/></p>
          
            <div class="subscribe-block margin-top-high">

              <subscribeForm
               :currentLang="currentLang"
               :latestIssueURLEnglish="latestIssueURLEnglish"
               :latestIssueURLGerman="latestIssueURLGerman"
               />
             </div>

          </div>
          <div class="column is-hidden-mobile">
            <img src="SmartphoneLanding.png" alt="A smartphone with the newsletter" class="smartphone"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section content aw-box">
    <p class="subtitle is-size-3">{{ texts[currentLang]["AUTOMATED SOCIETY_ is probably the best newsletter about Artificial Intelligence, by Europe's foremost organization on the topic."] }}</p>
    <div class="columns is-size-5">
      <div class="column">
        <p>
          <strong>{{ texts[currentLang]["Short."] }}</strong> {{ texts[currentLang]["Automated Society is a 5-minute read that provides the context you need to make sense of automated systems – and their regulation."] }}
        </p>

        <p>
          <strong>{{ texts[currentLang]["Accessible."] }}</strong> {{ texts[currentLang]["Automated Society provides over 7,000 decision-makers, academics and journalists with a selection of news stories and academic articles that explain how automated systems impact all of us."] }}
        </p>
      </div>
      <div class="column">

        <p>
          <strong>{{ texts[currentLang]["Sharp."] }}</strong> {{ texts[currentLang]["It cuts through the jargon and the exaggerations that clutter public discourse around new technologies and keeps only relevant pieces of information."] }}
        </p>

        <p>
          <strong>{{ texts[currentLang]["European."] }}</strong> {{ texts[currentLang]["It does not focus on stories from Silicon Valley that you have read elsewhere. Rather, it goes in depth, on our continent, to reveal how different groups of people use, or are subjected to, Artificial Intelligence, Machine Learning or simpler algorithms that can turn their lives upside down."] }}
        </p>
      </div>
    </div>
  </section>

  <section class="section aw-box">
    <div class="columns content">
      <div class="column">
      
        <blockquote class="testimonial">
          {{ texts[currentLang]["The Automated Society newsletter is a great resource for interesting news from around Europe - I’m an avid reader."] }}
        </blockquote>

        <div class="block">
          <p class="is-size-6"><strong>F.J. Zuiderveen Borgesius</strong>
          <br/><span class="is-size-7">{{ texts[currentLang]["Professor ICT and Law at the iHub, Radboud's interdisciplinary research hub on digitalization and society"] }}</span>
          <br/><span class="is-size-7">{{ texts[currentLang]["Radboud University, Nijmegen, The Netherlands"] }}</span></p>
        </div>
      </div>
      <div class="column">
        <blockquote class="testimonial">
          {{ texts[currentLang]["When I read Automated Society, I always find something I haven't heard about, with a strong European angle."] }}
          
        </blockquote>

        <div class="block">
          <p class="is-size-6"><strong>Lucie Ronfaut</strong>
          <br/><span class="is-size-7">{{ texts[currentLang]["Independent journalist and author of the #Règle30 newsletter"] }}</span>
          <br/><span class="is-size-7">{{ texts[currentLang]["France"] }}</span></p>
        </div>
      </div>
    </div>
  </section>

  <section class="section aw-box">
    <div class="columns">
        <div class="column subscribe-block is-two-thirds">

          <subscribeForm
           :currentLang="currentLang"
           :latestIssueURLEnglish="latestIssueURLEnglish"
           :latestIssueURLGerman="latestIssueURLGerman"
           />

      </div>
    </div>
  </section>

  <footerAW />
</template>

<script>
import axios from 'axios';
import subscribeForm from './components/subscribeForm.vue';
import headerAW from './components/headerAW.vue';
import footerAW from './components/footerAW.vue';
import texts from './assets/texts.js'

export default {
  name: 'Home',
  components: {
    headerAW,
    footerAW,
    subscribeForm
  },
  data() {
    return {
      latestIssue: 0,
      latestIssueURLEnglish: '',
      latestIssueURLGerman: '',
      currentLang: 'en',
      texts: texts
    }
  },
  methods : {
    setLang(value) {
      this.currentLang = value
    }
  },
  watch: {
    latestIssue() {
      var base_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : process.env.VUE_APP_SERVER_URL

      axios({
          method: 'GET',
          url: base_url + 'issue/issue ' + this.latestIssue,
          })
          .then(response => {
            var data = response.data
            this.latestIssueURLEnglish = data[0].URL_English
            this.latestIssueURLGerman = data[0].URL_German
            
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  created() {
    this.$watch(
          () => this.$route.params,
          () => {
            this.setLang(this.$route.params.lang)
          },
          { immediate: true })
  },
  mounted() {
    var base_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : process.env.VUE_APP_SERVER_URL
    axios({
        method: 'GET',
        url: base_url + 'tags',
        })
        .then(response => {
          // handle success
          this.isLoading = false
          this.tags = response.data
          
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
        .finally(() => {
          // finds latest issue
          this.tags.forEach((tag)=>{

            const regexpIssue = /issue (\d+)/;
            const match = tag["name"].match(regexpIssue);
            if (match) {
              if (match[1] > this.latestIssue) {
                // Needs to add -1 here, otherwise the issue being
                // worked on will be displayed.
                this.latestIssue = match[1] - 1
              }
            }
          })
        })
  }
}
</script>

<style>

@import "~bulma/css/bulma.css";

.title {
  text-transform: uppercase;
  font-size: 24px;
  color:#6D6D6D; 
  font-weight: 300;
  font-family: Open Sans Light, Open Sans, sans-serif;
}

.subtitle {
  font-size: 36px;
  color:#6D6D6D; 
  font-weight: 300;
  line-height: 1.5em;
  font-family: Open Sans Light, Open Sans, sans-serif;
}

.no-margin-bottom {
  margin-bottom:.1em !important;
}

.margin-top-high {
  margin-top: 5em !important;
}

.less-padding {
  padding-bottom: 2em !important;
  padding-top: 5em !important;
}

.frequency {
  font-size: 1.3em;
  font-weight: 300;
  font-family: Open Sans Light, Open Sans, sans-serif;
}

strong {
  color: #6D6D6D;
}

.smartphone {
  max-width: 170%;
  margin-left: -29%;
}

.testimonial {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.red_em {
  background: #E9CFCF;
  padding: 0 2px;
  margin: -2px;
}

.yellow_em {
  background: #FAF0D6;
  padding: 0 2px;
  margin: -2px;
}

.blue_em {
  background: #DCE5EC;
  padding: 0 2px;
  margin: -2px;
}

#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #6D6D6D;
}

.aw-box::before {
  background: linear-gradient(to right, #af140f, #e6a507, #247427, #22afe5, #493553, #af140f);
}

.subscribe-block {
  color:#6D6D6D; 
  margin-top: auto;
  font-weight: 300;
}

@media screen and (max-width: 450px) {
  .subtitle {
    font-size: 25px !important;
  }
}

</style>
