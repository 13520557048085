<template>
  <div class="block">
    <div class="field">
      <label class="label is-size-5">{{ texts[currentLang]["Filter by keyword"] }}:</label>
      <div class="control">
        <div class="dropdown" :class="{'is-active': filteredTags.length > 0 && typedTag != ''}">
              <div class="dropdown-trigger">
                <input class="input" type="text" placeholder="" v-model="typedTag">
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <span href="#" class="dropdown-item" 
                     v-for="(tag, index) in filteredTags" :key="index"
                     @click="selectTag(tag.name)">
                    {{ tag.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
  <div class="block" v-if="activeTags.length > 0">
    <div>{{ texts[currentLang]["Selected filters"] }}: 
      <div class="field is-grouped is-grouped-multiline tags">
        <span class="tag is-info" v-for="(tag, index) in activeTags" :key="index">
          {{tag}}
          <button class="delete is-small" @click="removeFilter(index)"></button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import texts from '../assets/texts.js'


export default {
  name: 'SearchBar',
  emits: ["isLoading", 'setItems', 'setActiveTags'],
  data() {
    return {
      filteredTags: [],
      activeTags: [],
      typedTag: "",
      texts: texts
    }
  },
  props: [ 'isLoading', 'latestIssue', 'tags', 'tagsFromURL', 'currentLang' ],
  mounted() {
    this.activeTags = this.tagsFromURL
    this.getItems()
  },
  watch : {
    typedTag() {
      this.filterTags()
    }
  },
  methods : {
    removeFilter(index) {
      this.activeTags.splice(index,1)
      this.getItems()
    },
    selectTag(tagName) {
      this.activeTags.push(tagName)
      this.typedTag = ""
      this.getItems()
    },
    filterTags() {
      this.filteredTags = this.tags.filter((tag) => {
                        return tag.name.toLowerCase().startsWith(this.typedTag.toLowerCase());
                    });
    },
    getItems() {
      this.$emit('setActiveTags', this.activeTags)
      this.$emit('isLoading', true)

      var base_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : process.env.VUE_APP_SERVER_URL

      axios({
          method: 'GET',
          url: base_url + 'items/' + this.activeTags.join(","),
          })
          .then(response => {
            // handle success
            this.$emit('isLoading', false)
            this.$emit('setItems', response.data)
          })
          .catch(error => {
            // handle error
            console.log(error)
          })
    }
  }
}
</script>

<style scoped>

.dropdown-item {
  color: #314477;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-trigger {
  margin-bottom:-14px;
}

.is-info {
  background: #4F7BA2 !important;
}

</style>
