<template>
  <headerAW 
    :currentLang="currentLang" />
  <main class="aw-box container is-max-desktop">

    <div id="newsletterContent">

    <!-- HEADER -->

    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->
          <div style="margin:0px auto;max-width:600px;" class="padding-mobile">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
              <tbody>
                <tr>
                  <td style="direction:ltr;padding:18px 0px;text-align:center;">
                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                    <div style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                      <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:290px;" ><![endif]-->
                      <div style="text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                          <tbody>
                            <tr>
                              <td style="padding:0px;padding-top:0px;padding-bottom:0px;word-break:break-word;">
                                <div style="margin:auto">
                                  <img src="https://algorithmwatch.org/en/wp-content/uploads/2023/02/Logo-AlgorithmWatch@2x.png" alt="AlgorithmWatch logo" style="width:60%; max-width:163px" />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--[if mso | IE]></td><td style="vertical-align:top;width:290px;" ><![endif]-->
                      <div style="text-align:top;direction:ltr;display:inline-block;vertical-align:middle;width:50%;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                          <tbody>
                            <tr>
                              <td style="padding:0px;padding-top:0px;padding-bottom:0px;word-break:break-word;">
                                <div>
                                  <div style="text-align:left;direction:ltr;display:inline-block;vertical-align:center;width:100%;">
                                    <div style="padding:0px 25px 0px 0px">
                                      <p style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" :href="currentLang == 'en' ? issueMeta.URL_English : issueMeta.URL_German" style="text-decoration: none;">

                                        <span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">

                                      {{ texts[currentLang]["View online version"] }}

                                         </span></a></p>
                                      <p v-if="issueMeta.URL_German != ''" style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" :href="currentLang=='en' ? issueMeta.URL_German : issueMeta.URL_English" style="text-decoration: none;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;" v-html="currentLang == 'en' ? 'Auf Deutsch lesen' : 'Read in English'"></span></a></p>
                                      <p style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" :href="currentLang=='en' ? 'https://automatedsociety.algorithmwatch.org/#/en/' : 'https://automatedsociety.algorithmwatch.org/#/de/'" style="text-decoration: none;">
                                        <span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">
                                          {{ texts[currentLang]["Subscribe"] }}
                                      </span></a></p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--[if mso | IE]></td></tr></table><![endif]-->
                    </div>
                    <!--[if mso | IE]></td></tr></table><![endif]-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->
    
    <!-- WELCOME BLOCK -->

    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->
      <div style="background-color: #F7F7F7; width: 100%;" class="padding-mobile">
          <div style="margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
              <tbody>
                <tr>
                  <td style="direction:ltr;padding:60px 0px 50px;text-align:center;">
                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                    <div style="text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;" class="content">
                        <div style="font-family: Open Sans Light, Open Sans, Helvetica, Arial, sans-serif; font-size: 35px; line-height: 48px; color:#6D6D6D; font-weight: 300; text-transform: uppercase;" class="automated-society-title">
                          {{ texts[currentLang]["Automated Society"] }}
                        </div>
                      
                        <Markdown :source="currentLang=='en' ? issueMeta.intro : issueMeta.intro_German" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; line-height: 130%; color:#6D6D6D; margin-top:20px;"/>

                        <p style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; line-height: 130%; color:#6D6D6D; margin-top:18px">
                          {{ texts[currentLang]["This email was forwarded to you? You can"] }} <a target="_blank" :href="currentLang=='en' ? 'https://automatedsociety.algorithmwatch.org/#/en/' : 'https://automatedsociety.algorithmwatch.org/#/de/'" style="text-decoration: underline;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;">{{ texts[currentLang]["subscribe here"] }}</span></a>.
                        </p>
                        <div style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; line-height: 130%; color:#6D6D6D; margin-top:20px; display: flex;">
                          <div style="display: inline;">
                            <img style="border: 0; display:inline; line-height: 100%; object-fit: cover; outline: 0; text-decoration: none; -ms-interpolation-mode: bicubic; width: 64px; height: 64px; border-radius: 100%;" :alt="issueMeta.author.name" :src="issueMeta.author.picture" width=64 height=64 />
                          </div>
                          <div style="display: inline; margin: auto 24px">
                           <a target="_blank" :href="issueMeta.author.url" style="text-decoration: underline;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;">{{issueMeta.author.name}}</span></a> – {{ texts[currentLang][issueMeta.author.role] }}
                          </div>
                        </div>
                        <!-- Block showing translator -->
                        <div v-if="currentLang=='de'" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; line-height: 130%; color:#6D6D6D; margin-top:20px; display: flex;">
                          <div style="display: inline;">
                            <img style="border: 0; display:inline; line-height: 100%; object-fit: cover; outline: 0; text-decoration: underline; -ms-interpolation-mode: bicubic; width: 64px; height: 64px; border-radius: 100%;" :alt="issueMeta.translator.name" :src="issueMeta.translator.picture" width=64 height=64/>
                          </div>
                          <div style="display: inline; margin: auto 24px">
                           <a target="_blank" :href="issueMeta.translator.url" style="text-decoration: underline;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;">{{issueMeta.translator.name}}</span></a> – Übersetzer
                          </div>
                        </div>
                    </div>
                    <!--[if mso | IE]></td></tr></table><![endif]-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
          <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->

        <!-- DIVIDER -->
        <div style="display: block; height: 6px; background: linear-gradient(to right, #af140f, #e6a507, #247427, #22afe5, #493553, #af140f);">
          &nbsp;
        </div>

        <!-- BRIEFING -->
        <Section 
          v-if="itemsLoaded && items.Story.length > 0"
          :items="items.Story"
          :currentLang="currentLang"
          :colorMain="'#920D0F'"
          :colorSecondary="'#E9CFCF'"
          :sectionTitle="texts[currentLang]['The Briefing']"
          :itemType="'story'"
          :displayTags="false"
        />

        <!-- ALGORITHM NEWS FROM EUROPE -->
        <Section 
          v-if="itemsLoaded && inEurope(items.NewsItem, true).length > 0"
          :items="inEurope(items.NewsItem, true)"
          :currentLang="currentLang"
          :colorMain="'#4F7BA2'"
          :colorSecondary="'#DCE5EC'"
          :sectionTitle="texts[currentLang]['Algorithm news from Europe']"
          :itemType="'news'"
          :displayTags="false"
        />
        
        <!-- WE READ IT -->
        <Section 
          v-if="itemsLoaded && items.WeReadItItem.length > 0"
          :items="items.WeReadItItem"
          :currentLang="currentLang"
          :colorMain="'#40731D'"
          :colorSecondary="'#D9E3D2'"
          :sectionTitle="texts[currentLang]['We read it, so you don\'t have to']"
          :itemType="'weReadIt'"
          :displayTags="false"
        />

        <!-- OPINION -->
        <Section 
          v-if="itemsLoaded && items.OpinionItem.length > 0"
          :items="items.OpinionItem"
          :currentLang="currentLang"
          :colorMain="'#40731D'"
          :colorSecondary="'#D9E3D2'"
          :sectionTitle="texts[currentLang]['Opinions']"
          :itemType="'opinion'"
          :displayTags="false"
        />

        <!-- SHARE CALL TO ACTION -->
        <div style="margin:48px auto 0;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
            <tbody>
              <tr>
                <td style="direction:ltr;padding:0px;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                  <div style="text-align:left;display:inline-block;max-width:100%;direction:ltr;vertical-align:top;background-color: #FAF0D6; padding: 30px 24px;">
                    <p style="margin:auto; color:#404040;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:18px;line-height:130%;">
                      {{ texts[currentLang]["Please help us reach out to people who could be interested in automated decision-making in Europe."] }}
                      
                    </p>
                    <p style="margin:29px auto 8px; color:#404040;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:22px;line-height:130%; font-weight: bold;">
                      {{ texts[currentLang]["Recommend this newsletter over"] }}
                       

                      <a :href="'mailto:?subject=' + encodeURIComponent(texts[currentLang]['share_emailsubject']) + '&amp;body=' + encodeURIComponent(texts[currentLang]['share_emailbody']) + encodeURIComponent(issueMeta.URL_English)" style="color:#314477; text-decoration: underline;">
                        {{ texts[currentLang]["email"] }}
                      </a>, 

                      <a target="_blank" :href="currentLang == 'en' ? 'https://www.linkedin.com/sharing/share-offsite/?title=' + texts[currentLang]['as seen in Automated Society'] + issueMeta.title + '&source=AlgorithmWatch&url=' + issueMeta.URL_English : 'https://www.linkedin.com/sharing/share-offsite/?title=' + texts[currentLang]['as seen in Automated Society'] + issueMeta.title_German + '&source=AlgorithmWatch&url=' + issueMeta.URL_German" style="color:#0088CC; text-decoration: underline;">LinkedIn</a>, 

                      <a target="_blank" :href="currentLang == 'en' ? 'https://toot.kytta.dev/?text=' + texts[currentLang]['as seen in Automated Society'] + encodeURIComponent(issueMeta.title) + '%20' + issueMeta.URL_English : 'https://toot.kytta.dev/?text=' + texts[currentLang]['as seen in Automated Society'] + encodeURIComponent(issueMeta.title_German) + '%20' + issueMeta.URL_German" style="color:#595AFF; text-decoration: underline;">Mastodon</a>&nbsp;{{ texts[currentLang]["or"] }} 

                      <a target="_blank" :href="'https://teams.microsoft.com/share?href='+ issueMeta.URL_English +'&amp;referrer=algorithmwatch.org'" style="color:#4B53BC; text-decoration: underline;">Teams</a>.
                    </p>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->

        <!-- OTHER ALGORITHM NEWS -->
        <Section 
          v-if="itemsLoaded && inEurope(items.NewsItem, false).length > 0"
          :items="inEurope(items.NewsItem, false)"
          :currentLang="currentLang"
          :colorMain="'#6D6D6D'"
          :colorSecondary="'#E2E2E2'"
          :sectionTitle="texts[currentLang]['Other algorithm news']"
          :itemType="'news'"
          :displayTags="false"
        />

        <!-- MORE FROM AW -->
        <Section 
          v-if="itemsLoaded && items.AlgorithmWatchPromo.length > 0"
          :items="items.AlgorithmWatchPromo"
          :currentLang="currentLang"
          :colorMain="'#7AB6EC'"
          :colorSecondary="'#E4F0FB'"
          :sectionTitle="texts[currentLang]['More from AlgorithmWatch']"
          :itemType="'story'"
          :displayTags="false"
        />

        <!-- FOOTER ITEM -->
        <Section 
          v-if="itemsLoaded && items.FooterItem.length > 0"
          :items="items.FooterItem"
          :currentLang="currentLang"
          :colorMain="'#E7B434'"
          :colorSecondary="'#FAF0D6'"
          :sectionTitle="currentLang=='en' ? items.FooterItem[0].heading : items.FooterItem[0].heading_German"
          :itemType="'opinion'"
          :displayTags="false"
        />

        <!-- THAT'S IT FOLKS -->

        <div style="margin:0px auto;max-width:600px; overflow: hidden;" >

          <div style="display: block; margin: 48px 0 0; height: 2px; background: #404040" />

          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%; margin-top:12px;" >
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                  <div class="padding-mobile" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                    <p style="color: #404040; font-family:Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; letter-spacing: 0; line-height: 130%;" v-html="texts[currentLang]['thats it']">
                    </p>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- DIVIDER -->
        <div style="display: block; margin: 48px 0 0; height: 6px; background: linear-gradient(to right, #af140f, #e6a507, #247427, #22afe5, #493553, #af140f);">
          &nbsp;
        </div>

        <!-- FOOTER -->

        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="background-image: url('https://algorithmwatch.org/en/wp-content/uploads/2023/03/FooterBG-alt.png'); background-color: #F8F8F8; width: 100%">
          <div style="margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
              <tbody>
                <tr>
                  <td style="direction:ltr;padding:20px 20px 20px 0px;text-align:center;">
                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                    <div style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                      <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:290px;" ><![endif]-->
                      <div style="text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                          <tbody>
                            <tr>
                              <td style="padding:0px 0px 0px 20px;word-break:break-word;">
                                <div style="margin:auto; color:#404040;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">
                                  <p style="margin: 2px 0px;"><strong>AW AlgorithmWatch gGmbH CC-BY</strong></p>
                                  <p style="margin: 2px 0px;">
                                    Linienstraße 13, 10178 Berlin
                                  </p>
                                  <p style="margin: 2px 0px;">
                                     {{texts[currentLang]['Germany']}}
                                   </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--[if mso | IE]></td><td style="vertical-align:top;width:290px;" ><![endif]-->
                      <div style="text-align:top;direction:ltr;display:inline-block;vertical-align:middle;width:50%;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                          <tbody>
                            <tr>
                              <td style="padding:0px;padding-top:0px;padding-bottom:0px;word-break:break-word;">
                                <div>
                                  <div style="text-align:left;direction:ltr;display:inline-block;vertical-align:center;width:100%;">
                                    <div style="padding:0px 25px 0px 0px">
                                      <p style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" href="[[PERMALINK]]" style="text-decoration: none;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">{{texts[currentLang]['View online version']}}</span></a></p>
                                      <p style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" :href="currentLang=='en' ? 'https://algorithmwatch.org/en/donate/' : 'https://algorithmwatch.org/de/spenden/'" style="text-decoration: none;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">{{texts[currentLang]['Donate']}}</span></a></p>
                                      <p style="text-align: right; margin: 2px 0; margin-top: 2px; margin-bottom: 2px;"><a target="_blank" :href="currentLang=='en' ? '[[UNSUB_LINK_EN]]' : '[[UNSUB_LINK_DE]]'" style="text-decoration: none;"><span style="color:#314477;font-family:Open Sans, Helvetica, Arial, sans-serif;font-size:13px;line-height:130%;">{{texts[currentLang]['Unsubscribe']}}</span></a></p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--[if mso | IE]></td></tr></table><![endif]-->
                    </div>
                    <!--[if mso | IE]></td></tr></table><![endif]-->
                  </td>
                </tr>
                <tr>
                  <td style="direction:ltr;padding:20px 0px 20px 0px;padding-left:0px;padding-right:0px;text-align:center;">
                      <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                      <div style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                        <div style="background-image: url('https://algorithmwatch.org/en/wp-content/uploads/2023/02/Logo-AlgorithmWatch.png'); width:50px; height: 45px; margin: 0 auto;">
                          &nbsp;
                        </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td style="direction:ltr;padding:0px 0px 20px 0px;padding-left:0px;padding-right:0px;text-align:center;">
                      <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
                      <div style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                        <div style="margin: 10px auto; width:fit-content;">
                          <a style="margin: 0 5px; color:#314477" href="https://chaos.social/@algorithmwatch"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-2 -2 30 30" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="currentColor"><path d="M25.426 15.547c-.383 1.844-3.422 3.844-6.914 4.226-1.813.2-3.602.391-5.524.313a34.967 34.967 0 0 1-5.582-.695c0 .28.012.558.047.804.41 2.875 3.063 3.043 5.57 3.133 2.547.067 4.805-.594 4.805-.594l.094 2.125s-1.766.895-4.922 1.063c-1.75.09-3.914-.043-6.434-.672C1.106 23.922.168 18.496.023 12.996-.023 11.352.012 9.81.012 8.5c0-5.625 3.96-7.266 3.96-7.266C5.954.394 9.376.027 12.943.004h.081c3.567.023 6.989.39 8.98 1.23 0 0 3.962 1.653 3.962 7.29 0 0 .047 4.148-.55 7.035"></path><path fill="white" stroke="white" stroke-width=".1" d="M21.309 8.95v6.82h-2.895V9.172c0-1.399-.637-2.102-1.894-2.102-1.395 0-2.09.84-2.09 2.492v3.626h-2.883V9.55c0-1.653-.695-2.492-2.086-2.492-1.262-.012-1.899.703-1.899 2.101v6.61H4.684V8.95c0-1.387.382-2.497 1.136-3.31.793-.816 1.828-1.23 3.11-1.23 1.488 0 2.605.524 3.351 1.586L13 7.113l.723-1.117c.726-1.059 2.011-1.672 3.359-1.598 1.273 0 2.305.426 3.098 1.243a4.667 4.667 0 0 1 1.14 3.32"></path></svg></a>

                          <a style="margin: 0 5px; color:#314477" title="LinkedIn" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/algorithmwatch/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"><path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path><circle cx="4" cy="4" r="2"></circle></svg></a>

                          <a style="margin: 0 5px; color:#314477" title="Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pg/algorithmwatch/posts/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" style="margin-left:-5px;margin-right:-3px" fill="currentColor" stroke="currentColor" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"><path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path></svg></a>

                          <a style="margin: 0 5px; color:#314477" title="Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/algorithmwatch/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path fill="white" d="M16 11.4A4 4 0 1112.6 8a4 4 0 013.4 3.4zM17.5 6.5h0"></path></svg></a>

                          <a style="margin: 0 5px; color:#314477" title="Youtube" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCOhGvpEH0oLlpqZDDpDo67Q"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" stroke="currentColor" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round" d="M22.5 6.4a2.8 2.8 0 00-1.9-2C18.9 4 12 4 12 4s-6.9 0-8.6.5a2.8 2.8 0 00-2 2 29 29 0 00-.4 5.3 29 29 0 00.5 5.3A2.8 2.8 0 003.4 19c1.7.5 8.6.5 8.6.5s6.9 0 8.6-.5a2.8 2.8 0 002-2 29 29 0 00.4-5.3 29 29 0 00-.5-5.3z"></path><path fill="white" d="M9.8 15l5.7-3.3-5.8-3.2V15z"></path></svg></a>

                              </div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;mso-line-height-rule:exactly;"><![endif]-->
        </div>
      </div>

    <section class="section content">
      <button class="button" @click="copyHTML()">Copy HTML of current selection to clipboard</button>
    </section>
  </main>
  <footerAW />
</template>

<script>

import axios from 'axios';
import people from './assets/people.js'
import Section from './components/Section.vue';
import headerAW from './components/headerAW.vue';
import footerAW from './components/footerAW.vue';
import texts from './assets/texts.js'
import Markdown from 'vue3-markdown-it';

export default {
  name: 'Newsletter',
  components: {
    Section,
    headerAW,
    footerAW,
    Markdown
  },
  data() {
    return {
      issue: '',
      itemsLoaded: false,
      issueMeta: {
        "URL_German": "",
        "URL_English" : "",
        "author": {
          "name" : "",
          "url" : "",
          "picture":""
        },
        "translator" : {
          "name" : "",
          "url" : "",
          "picture":""
        },
        "title": "",
        "title_German": "",
        "intro": "",
        "intro_German" : ""
        },
      items: [],
      isLoading: true,
      currentLang: 'en',
      texts: texts
    }
  },
  methods : {
    setSearchLoading(value) {
      this.isLoading = value
    },
    setItems(value) {
      this.items = value
    },
    setIssue(value) {
      this.issue = value
    },
    setIssueMeta(value) {
      this.issueMeta = value
      this.issueMeta.author = people[this.issueMeta.author]
      this.issueMeta.translator = people[this.issueMeta.translator]
    },
    setLang(value) {
      this.currentLang = value
    },
    inEurope(items, yes) {

      // Returns a list of items that contain a European country as tag
      const EuropeanCountries = ["European Union", "Albania","Andorra","Austria","Belarus","Belgium","Bosnia and Herzegovina","Bulgaria", "Croatia","Cyprus","Czechia","Czech Republic","Denmark","Estonia","Finland","France","Germany","Greece","Hungary","Iceland","Ireland","Italy","Kosovo","Latvia","Liechtenstein","Lithuania","Luxembourg","Malta","Moldova","Monaco","Montenegro","Netherlands","North Macedonia", "Norway","Poland","Portugal","Romania", "Serbia","Slovakia","Slovenia","Spain","Sweden","Switzerland","Turkey","Ukraine","United Kingdom","Vatican", "England", "Scotland", "Catalonia", "Wales", "Northern Ireland"]
      if (yes) { 
        return items.filter(item => item.tags.some(tag => EuropeanCountries.includes(tag)))
      } else {
        return items.filter(item => !item.tags.some(tag => EuropeanCountries.includes(tag)))
      }
    },
    copyHTML() {
      const newsletterContent = document.getElementById("newsletterContent");
      const elem = document.createElement('textarea');
      document.body.appendChild(elem);
      elem.value = this.transformHTML(newsletterContent.innerHTML);
      elem.select(); 
      document.execCommand("copy");
      document.body.removeChild(elem);
    },
    transformHTML(str) {
      
      const title = this.currentLang == "en" ? this.issueMeta.title : this.issueMeta.title_German

      str = `<!doctype html><html>
      <head>
      <meta charset="UTF-8" />
      <title>` + title + ` – Automated Society</title>
      <link rel="icon shortcut" href="https://static.algorithmwatch.org/gfx/favicon-32x32.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:image" content="https://automatedsociety.algorithmwatch.org/share.jpg"/>
      <style>
        @import url('https://static.algorithmwatch.org/fonts/open-sans-v17-latin-regular.woff2');

        @media screen and (min-width: 451px) {
          .automated-society-title {
            font-size: 35px;
          }
        }

        @media screen and (max-width: 450px) {
          .automated-society-title {
            font-size: 25px !important;
          }
          .padding-mobile {
          }
        }

        * {

          -ms-text-size-adjust: 100%;

        }
      </style>
      </head>
      <body style="margin: 0; padding: 0 !important;">` + str + '</body></html>'

      // Removes comments
      str = str.replace(/<!--v-if-->/gi, '')

      // Fixes line height on Markdown titles
      str = str.replace(/<h2 id=/g, '<h2 style="line-height:1.4em; margin:0;" id=')
      
      // Not a great hack: adds the max width CSS rule to
      // images that were added in the markdown so that
      // they be responsive on mobile
      str = str.replace(/<p><img src="https:\/\/algorithmwatch.org\/en\/wp-content\/uploads\//,'<p><img style="max-width:100%" src="https://algorithmwatch.org/en/wp-content/uploads/')

      // Format links
      str = str.replace(/<a href=/g, '<a style="color:#314477; text-decoration: underline;" rel="notrack" href=')
      return str
    }
  },
  created () {
    this.$watch(
          () => this.$route.params,
          () => {
            this.setIssue(this.$route.params.issue)
            this.setLang(this.$route.params.lang)
          },
          { immediate: true })
  },
  mounted() {

    var base_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : process.env.VUE_APP_SERVER_URL

    // Gets meta information such as title and URLs
    axios({
        method: 'GET',
        url: base_url + 'issue/' + this.issue,
        })
        .then(response => {
          // handle success
          this.isLoading = false
          this.setIssueMeta(response.data[0])
        })
        .catch(error => {
          // handle error
          console.log(error)
        })

    // Gets newsletter items
    axios({
        method: 'GET',
        url: base_url + 'items/' + this.issue,
        })
        .then(response => {
          // handle success
          this.isLoading = false
          this.setItems(response.data)
          this.itemsLoaded = true
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
  }
}
</script>

<style>

@import "~bulma/css/bulma.css";

#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

h2 {
  line-height: 1.4em !important;
  margin: 0 !important;
}

.content a {
  color: #314477 !important;
}

textarea {
  left: -1000px;
}

#newsletterContent {
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.content {
  max-width: inherit;
  margin-bottom:  0;
}

.aw-box {
  max-width: 1200px;
}

@media screen and (min-width: 451px) {
  .automated-society-title {
    font-size: 35px;
  }
}

@media screen and (max-width: 450px) {
  .automated-society-title {
    font-size: 25px !important;
  }
  .padding-mobile {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
</style>
