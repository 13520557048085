<template>
  <div style="margin:0px auto;max-width:600px; overflow: hidden; padding-top:48px">

    <div :style="'margin:0px 0 -1px 0;max-width:600px;height: 2px; display:block; background-color: ' + colorSecondary + '; position: relative;'">
    </div>
    <div :style="'background-color: ' + colorSecondary + '; display: inline-block; padding: 2px 15px 2px 20px; position: relative; z-index:1; margin: 0 0 0 -15px; border-radius: 0 0 8px 0; transform:skew(-20deg)'">
      <div :style="'margin: 0px; transform:skew(20deg); text-transform: uppercase; color:' + colorMain + '; font-size: 14px; line-height:24px; font-weight: bold; font-family: Open Sans, sans-serif; padding-left:4px'">
        {{sectionTitle}}
      </div>
    </div>
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%; margin-top:12px;">
      <tbody>
        <tr>
          <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:580px;" ><![endif]-->
            <div class="padding-mobile" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:top;">
                  <Item v-for="(item, index) in items" 
                  :key="index" 
                  :item="item"
                  :lang="currentLang"
                  :itemType="itemType"
                  :displayTags="displayTags"
                  :colorMain="colorMain"
                  />
            </div>
            <!--[if mso | IE]></td></tr></table><![endif]-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
//import langs from '../assets/lang.js';
import Item from '../components/Item.vue';

export default {
  name: 'Section',
  components: { Item },
  props: [ 'items', 'sectionTitle', 'lang', 'colorMain', 'colorSecondary', 'itemType', 'currentLang', 'displayTags' ]
}
</script>
<style scoped>

</style>