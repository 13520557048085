import { createApp } from 'vue/dist/vue.esm-bundler'
import * as VueRouter from 'vue-router';
import Archive from './Archive.vue'
import Thanks from './Thanks.vue'
import Newsletter from './Newsletter.vue'
import Home from './Home.vue'

const routes = [
  { path: '/', redirect: '/en' },
  { path: '/:lang', component: Home, name: 'home' },
  { path: '/:lang/thanks', component: Thanks, name: 'thanks' },
  { path: '/:lang/archive/:tags?', component: Archive, name: 'archive' },
  { path: '/:lang/newsletter/:issue', component: Newsletter, name: 'newsletter' }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})

const app = createApp({})

app.use(router)

app.mount('#app')