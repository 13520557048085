// The IDs of the people below must match the IDs
// of the variable AUTHORS_CHOICES in /server/api/models.py
const people = {
    "0" : {"name" : "Nicolas Kayser-Bril",
          "url_German" : "https://algorithmwatch.org/de/team/nicolas-kayser-bril/",
          "url" : "https://algorithmwatch.org/en/team/nicolas-kayser-bril/",
          "picture":"https://algorithmwatch.org/de/wp-content/uploads/2021/02/3744px_Nicolas-Kayser-Bril-AlgorithmWatch-Photo-Julia-Bornkessel-CC-BY-4.0-BW-500.jpg",
          "role": "author"
      },
    "1" : {"name" : "Waldemar Kesler",
           "url_German" : "https://algorithmwatch.org/de/team/waldemar-kesler/",
          "url" : "https://algorithmwatch.org/en/team/waldemar-kesler/",
          "picture":"https://algorithmwatch.org/de/wp-content/uploads/2022/03/1500px_waldmar_kesler_portrait_square-1-500.jpg",
          "role": "translator"
      },
    "2" : {"name" : "Josephine Lulamae",
          "url" : "https://algorithmwatch.org/en/team/josephine-huetlin/",
          "url_German" : "https://algorithmwatch.org/de/team/josephine-huetlin/",
          "picture":"https://algorithmwatch.org/de/wp-content/uploads/2021/11/1500px_jh_aw-cropped-quadr-500.jpg",
          "role": "author_f"
      },
    "3" : {"name" : "Andreas Bredenfeld",
          "url" : "https://www.textetage.com/en/home/uebersetzungen/show/andreas-bredenfeld.html",
          "url_German" : "https://www.textetage.com/en/home/uebersetzungen/show/andreas-bredenfeld.html",
          "picture":"https://algorithmwatch.org/en/wp-content/uploads/2023/07/AndreasBrendenfeld.png",
          "role": "translator"
      },
    "4" : {"name" : "Naiara Bellio",
          "url" : "https://algorithmwatch.org/en/team/naiara-bellio/",
          "url_German" : "https://algorithmwatch.org/de/team/naiara-bellio/",
          "picture":"https://algorithmwatch.org/de/wp-content/uploads/2023/10/3839px_Naiara-Bellio-800.jpg",
          "role": "author_f"
      }
}

export default people;
