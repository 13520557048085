<template>
  <headerAW 
    :currentLang="currentLang" />
  <main class="aw-box container is-max-desktop">
    <section class="section content">
      <div class="columns">
        <div class="column">
          <h2 class="title">{{ texts[currentLang]["Archive"] }}</h2>
          <p class="is-size-5">{{ texts[currentLang]["Browse through over 1,000 items that were featured in the newsletter since 2019. You can filter the list by keyword using the search bar."] }}</p>
        </div>
        <div class="column">
          <SearchBar :isLoading='isLoading'
                 :tags='tags'
                 :currentLang='currentLang'
                 :tagsFromURL = 'tagsFromURL'
                 @isLoading='setSearchLoading'
                 @setActiveTags='setActiveTags'
                 @setItems='setItems'/>
        </div>
      </div>
      <div v-if="!isLoading && itemsSet">
        <Section 
            v-if="!isLoading && items.NewsItem.length > 0"
            :items="items.NewsItem"
            :currentLang="currentLang"
            :colorMain="'#4F7BA2'"
            :colorSecondary="'#DCE5EC'"
            :sectionTitle="texts[currentLang]['News items']"
            :itemType="'news'"
            :displayTags="true"
          />
          <Section 
            v-if="!isLoading && items.WeReadItItem.length > 0"
            :items="items.WeReadItItem"
            :currentLang="currentLang"
            :colorMain="'#40731D'"
            :colorSecondary="'#D9E3D2'"
            :sectionTitle="texts[currentLang]['We read it, so you don\'t have to']"
            :itemType="'weReadIt'"
            :displayTags="true"
          />
          <Section 
            v-if="!isLoading && [...items.OpinionItem, ...items.FooterItem].length > 0"
            :items="[...items.OpinionItem, ...items.FooterItem]"
            :currentLang="currentLang"
            :colorMain="'#E7B434'"
            :colorSecondary="'#FAF0D6'"
            :sectionTitle="texts[currentLang]['Opinions and other links']"
            :itemType="'opinion'"
            :displayTags="true"
          />
          <Section 
            v-if="!isLoading && items.Story.length > 0"
            :items="items.Story"
            :currentLang="currentLang"
            :colorMain="'#920D0F'"
            :colorSecondary="'#E9CFCF'"
            :sectionTitle="texts[currentLang]['Briefings']"
            :itemType="'story'"
            :displayTags="true"
          />
      </div>
    </section>
  </main>
  <footerAW />
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import headerAW from './components/headerAW.vue';
import footerAW from './components/footerAW.vue';
import texts from './assets/texts.js'
import Section from './components/Section.vue';
import axios from 'axios';


export default {
  name: 'Archive',
  components: {
    SearchBar,
    headerAW,
    footerAW,
    Section
  },
  data() {
    return {
      tags: [],
      tagsFromURL : [],
      activeTags: [],
      items: [],
      isLoading: true,
      itemsSet: false,
      currentLang: 'en',
      texts:texts
    }
  },
  methods : {
    setSearchLoading(value) {
      this.isLoading = value
    },
    setItems(value) {
      this.items = value
      this.itemsSet = true
      this.updateURL()
    },
    updateURL() {
      this.$router.push({ name: 'archive', params: { 'tags': this.activeTags.join(",") }})
    },
    setLang(value) {
      this.currentLang = value
    },
    setActiveTags(value) {
      this.activeTags = value
    },
    setTags(value) {
      if (value != '') {
        this.tagsFromURL = value.split(',')
        this.activeTags = this.tagsFromURL
      }
    }
  },
  created() {
    this.$watch(
          () => this.$route.params,
          () => {
            this.setTags(this.$route.params.tags)
            this.setLang(this.$route.params.lang)
          },
          { immediate: true })
  },
  mounted() {
    var base_url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : process.env.VUE_APP_SERVER_URL

    // Gets the list of all tags
    axios({
        method: 'GET',
        url: base_url + 'tags/',
        })
        .then(response => {
          // handle success
          this.isLoading = false
          this.tags = response.data
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
  }
}
</script>

<style scoped>

@import "~bulma/css/bulma.css";

.aw-box {
  max-width: 1200px;
}

.title {
  font-size: 36px;
  color:#6D6D6D; 
  font-weight: 300;
  text-transform: uppercase;
}
</style>
