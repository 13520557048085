<template>
  <div v-if="itemExistsinLanguage()" class="block">
    <div class="block content">
      <div v-if="itemType == 'story'"
           style='  color: #404040; font-family:Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; letter-spacing: 0; line-height: 130%;'>
        <Markdown :html="true" :source="getText()"/>
      </div>

      <div v-if="itemType == 'news'"
           style='  color: #404040; font-family:Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; letter-spacing: 0; line-height: 130%;' >
        <Markdown :html="true" :source="removeTrailingDot(getText()) + ' [<a href=' + item.url +'>' + item.publisher + '</a>, ' + formatDate(item.publication_date) + format_accessibility(item.accessibility) + getItemSourceLang() + ']. ' + getAdditionalText()"/>
      </div>

      <div v-if="itemType == 'opinion'"
           style='  color: #404040; font-family:Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; letter-spacing: 0; line-height: 130%;'>
        <p>
          <Markdown :source="getText()" />
        </p>
        <p v-if="item.title != ''" :style="'font-size:14px; padding-left:12px; line-height: 130%; border-left: 8px solid ' + colorMain + ';'">
          {{item.author}}. <a :href="item.url" style="color: #314477"> <strong>{{item.title}}</strong> </a><span v-if="item.publisher != ''">, {{item.publisher}}</span>, {{formatDate(item.publication_date)}}.
          <span v-html="getItemSourceLang()" />
        </p>
      </div>

      <div v-if="itemType == 'weReadIt'"
           style='  color: #404040; font-family:Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; letter-spacing: 0; line-height: 130%;'>
        <p>
          <Markdown :source="getText()" />
        </p>

        <p :style="'font-size:14px; padding-left:12px; line-height: 130%; border-left: 8px solid ' + colorMain + ';'">
          <span :style="'color:' + colorMain + '; font-weight:bold; text-transform:uppercase'">{{item.publicationType}}</span>
          <br>
          <span v-if="item.author != ''">{{item.author}}, </span>
          <a :href="item.url" style="color:#314477"> <strong>{{item.title}}</strong></a>
          <span v-if="item.publisher != ''">, <em>{{item.publisher}}</em></span>, {{formatDate(item.publication_date)}}.
          <span v-if="item.urlToUngatedVersion != ''">
            <a :href="item.urlToUngatedVersion" style="color:#314477">Link to ungated version</a>.
          </span>
          <span v-html="getItemSourceLang()" />
        </p>
      </div>
    </div>
    <div class="block" v-if="displayTags">
      <div class="field is-grouped is-grouped-multiline tags">
        <span class="tag is-light" v-for="(tag, index) in item.tags" :key="index">
          {{tag}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Markdown from 'vue3-markdown-it';
import langs from '../assets/lang.js';
import texts from '../assets/texts.js'

export default {
  name: 'Item',
  components: { Markdown },
  data (){ return {texts: texts} },
  props: [ 'item', 'itemType', 'lang', 'displayTags', 'colorMain' ],
  methods : {
    itemExistsinLanguage() {
      return this.lang == "en" ? this.item.text != "" : this.item.text_German != ""
    },
    getText() {
      return this.lang == "en" ? this.item.text : this.item.text_German 
    },
    getAdditionalText() {
      return this.lang == "en" ? this.item.additional_text : this.item.additional_text_German 
    },
    getItemSourceLang() {
      return this.lang == this.item.source_language ? '' : ', <em> ' + texts[this.lang]['in'] + ' ' + langs[this.item.source_language][this.lang] + '</em>'
    },
    format_accessibility(code) {
      if (code == 1) { return "" }
      else if (code == 2) {return " 🔓"}
      else if (code == 3) {return " 🔒"}
    },
    removeTrailingDot(str) {
      if (str.slice(-1) == ".") {
        return str.slice(0, -1)
      } else {
        return str
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      var options = { month: 'short', day: 'numeric' };
      if (new Date().getFullYear() != date.getFullYear()) {
         options["year"] = "numeric"
      }
      const dateLocale = this.lang == "en" ? 'en-GB' : 'de-DE'
      var datestr = new Intl.DateTimeFormat(dateLocale, options).format(date);

      // removes the trailing dot in German dates
      return this.lang == "en" ? datestr : datestr.slice(0, -1)

    }
  }
}
</script>
<style scoped>

  img {
    max-width: 100%;
  }

</style>